import { SimpleListAAC } from "../templates/simple-list-aac";

export const MyObjects = (props) => {
    return (
        <SimpleListAAC 
            widgetLabel={props.settings?.label} 
            icon="Commitments"
            fields={[
                'projet',
                'projet_url',
                'count',
            ]}
            dataUrl="/v1/aac/widgets/consent/liste/mesfiches"/>
    );
}
