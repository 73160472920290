import {WindowDevEditRegular} from "@fluentui/react-icons";
import { SimpleListAAC } from "../templates/simple-list-aac";

export const WriteProjects = (props) => {
    return (
        <SimpleListAAC widgetLabel={props.settings?.label} fluentIcon={<WindowDevEditRegular/>}
                    fields={[
                        'id', 
                        'projet',
                        'projet_url'
                    ]}
                    dataUrl="/v1/sit/widgets/projetsecriture/liste"/>
    );
}
