import {useAuth0} from "@auth0/auth0-react";
import {Icon} from '@fluentui/react/lib/Icon';
import {Button} from "@fluentui/react-components";

const LogoutIcon = () => <Icon iconName="SignOut"/>;

export const LogoutButton = () => {
    const {logout} = useAuth0();

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    return (
        <Button appearance="transparent" onClick={handleLogout} icon={<LogoutIcon/>}>
            Déconnexion
        </Button>
    );
};
