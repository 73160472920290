import {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {NavBarTab} from "./nav-bar-tab";
import {Button, makeStyles, shorthands, Switch} from "@fluentui/react-components";
import {Icon} from '@fluentui/react/lib/Icon';

const ProfileIcon = () => <Icon iconName="AccountManagement"/>,
    ConfigIcon = () => <Icon iconName="Settings"/>;
const useStyles = makeStyles({
    container: {display: 'flex', alignItems: 'center', justifyContent: 'flex-end', ...shorthands.flex(1)}
});

export const NavBarTabs = ({onModeChange}) => {
    const {isAuthenticated} = useAuth0();
    const classes = useStyles();
    const [mode, setMode] = useState('read');

    const toggleEditMode = (ev) => {
        const newMode = ev.currentTarget.checked ? 'edit' : 'read';
        setMode(newMode);
        onModeChange(newMode);
    }

    return (
        <div className={classes.container}>
            {isAuthenticated && (
                <>
                    {/*<NavBarTab path="/compte" label="Mon compte" icon={<ProfileIcon/>}/>*/}
                    {/*<NavBarTab path="/configuration" label="Configuration" icon={<ConfigIcon/>}/>*/}

                    <Switch
                        checked={mode === 'edit'}
                        label={`Mode ${mode === 'edit' ? 'édition' : 'consultation'}`}
                        labelPosition="after"
                        onChange={toggleEditMode}
                    />
                </>
            )}
        </div>
    );
};
