import {DocumentTableCheckmarkRegular} from "@fluentui/react-icons";
import { SimpleListAAC } from "../templates/simple-list-aac";

export const CompletedTranslations = (props) => {
    return (
        <SimpleListAAC widgetLabel={props.settings?.label} fluentIcon={<DocumentTableCheckmarkRegular/>}
                    fields={[
                        'date', 
                        'contrat',
                        'contrat_url',
                        'traducteur',
                    ]}
                    dataUrl="/v1/sit/widgets/traductions/contratsclos"/>
    );
}
