import {
    Button, Dialog,
    DialogActions,
    DialogBody, DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger, Select, Label,
    makeStyles
} from "@fluentui/react-components";
import {useEffect, useId, useState} from "react";
import {WidgetsComponents} from "./reference";
import {AppsAddInRegular} from "@fluentui/react-icons";

const useStyles = makeStyles({
    btn: {marginLeft: '20px', lineHeight: '28px'},
    select: {'& > select': {lineHeight: '32px'}},
    content: {marginTop: '10px', marginBottom: '20px'}
});

export const AddWidget = ({handleCreateWidget, isOpen, openChange, parentWidget}) => {
    const classes = useStyles();
    const selectId = useId();
    const dashboardWidgets = Object.keys(WidgetsComponents);
    const [widgetType, setWidgetType] = useState('');

    useEffect(() => {
        setWidgetType(Object.keys(WidgetsComponents)[0]);
    }, [isOpen]);

    if (parentWidget) {
        const canvasWidgetIndex = dashboardWidgets.findIndex((w) => w === 'cardCanvas');
        dashboardWidgets.splice(canvasWidgetIndex, 1);
    }

    const widgetsList = dashboardWidgets.sort( w => `${WidgetsComponents[w].group}-${WidgetsComponents[w].label}`)
        .reduce((prev, w, idx, arr) => {
            prev[WidgetsComponents[w].group] ||= [];
            prev[WidgetsComponents[w].group].push(w);
            return prev;
        }, {});

    return (
        <Dialog modalType="non-modal" open={isOpen} onOpenChange={(event, data) => openChange(data.open)}>
            <DialogTrigger disableButtonEnhancement>
                <Button className={classes.btn} appearance="primary" size="small" icon={<AppsAddInRegular />}>Ajouter un widget</Button>
            </DialogTrigger>
            <DialogSurface aria-describedby={undefined}>
                <DialogBody>
                    <DialogTitle>Ajouter un widget</DialogTitle>
                    <DialogContent className={classes.content}>
                        <Label required htmlFor={selectId}>Type de widget</Label>
                        <Select id={selectId} className={classes.select} onChange={(t) => setWidgetType(t.target.value)}>
                            {
                                Object.keys(widgetsList).map( (group) => {
                                    return (
                                        <optgroup key={group} label={group}>
                                            {
                                                widgetsList[group].map( w => {
                                                    return (<option key={w} value={w}>{WidgetsComponents[w].label}</option>);
                                                })
                                            }
                                        </optgroup>
                                    );
                                })
                            }
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Fermer</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="primary" onClick={() => handleCreateWidget(widgetType)}>Valider</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
