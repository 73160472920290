import {PageLayout} from "../components/page-layout";
import {makeStyles, mergeClasses} from "@fluentui/react-components";
import logo from "../assets/logo_apidae_h.png";

const useStyles = makeStyles({
    center: { alignItems: 'center' }
});

export const NotFoundPage = () => {
    const classes = useStyles();

    return (
        <PageLayout>
            <div className={mergeClasses('content-layout', classes.center)}>
                <img
                    src={logo}
                    alt="logo Apidae"
                    width="300"
                    height="auto"
                />
                <h1>
                    La page que vous recherchez n'existe pas.
                </h1>
            </div>
        </PageLayout>
    );
};
