import {useAuth0} from "@auth0/auth0-react";
import {LoginButton} from "../../buttons/login-button";
import {LogoutButton} from "../../buttons/logout-button";
import {ApidaeSwitcher} from "apidae-switcher";
import {makeStyles} from "@fluentui/react-components";

const useStyles = makeStyles({
    nav: {display: 'flex'}
});

export const NavBarButtons = () => {
    const classes = useStyles();
    const {isAuthenticated} = useAuth0();

    return (
        <div className={classes.nav}>
            {!isAuthenticated && (
                <>
                    <LoginButton/>
                </>
            )}
            {isAuthenticated && (
                <>
                    <LogoutButton/>
                </>
            )}
            <ApidaeSwitcher height={44} align={'right'}></ApidaeSwitcher>
        </div>
    );
};
