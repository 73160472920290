import {Spinner} from "@fluentui/react-components";

export const PageLoader = () => {
  return (
    <div className="txtcenter pal">
        <p>Chargement en cours...</p>
        <Spinner />;
    </div>
  );
};
