import {SingleButton} from "../templates/single-button";

export const NewAnnounce = (props) => {
    return (
        <SingleButton widgetLabel={props.settings?.label || 'Nouvelle Annonce'} 
                    icon="AddToShoppingList"
                    url="/annonces/creation-d-une-nouvelle-annonce"
                    consent={true}
        />
    );
}
