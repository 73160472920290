import {ValidationRequests} from "./widgets/validation-requests";
import {DeletionRequests} from "./widgets/deletion-requests";
import {HidingRequests} from "./widgets/hiding-requests";
import {ActiveRequests} from "./widgets/active-requests";
import {HiddenObjects} from "./widgets/hidden-objects";
import {BrokenLinks} from "./widgets/broken-links";
import {QuarantinedMedia} from "./widgets/quarantined-media";
import {NearlyQuarantinedMedia} from "./widgets/nearly-quarantined-media";
import {DatesToConfirm} from "./widgets/dates-to-confirm";
import {UnusedEntities} from "./widgets/unused-entities";
import {ObjectsWithoutPicture} from "./widgets/objects-without-picture";
import {PendingGeolocation} from "./widgets/pending-geolocation";
import {ObjectsWithoutDesc} from "./widgets/objects-without-desc";
import {ExpiredOpenings} from "./widgets/expired-openings";
import {ObsoleteObjects} from "./widgets/obsolete-objects";
import {ArchivedObjects} from "./widgets/archived-objects";
import {UnusedObjects} from "./widgets/unused-objects";
import {OwnedObjects} from "./widgets/owned-objects";
import {DelegatedObjects} from "./widgets/delegated-objects";
import {SentObjects} from "./widgets/sent-objects";
import {PendingObjects} from "./widgets/pending-objects";
import {SentRequests} from "./widgets/sent-requests";
import {ReceivedRequests} from "./widgets/received-requests";
import {RequestedTranslations} from "./widgets/requested-translations";
import {PendingTranslations} from "./widgets/pending-translations";
import {AwaitingTranslations} from "./widgets/awaiting-translations";
import {CompletedTranslations} from "./widgets/completed-translations";
import {History} from "./widgets/history";
import {WriteProjects} from "./widgets/write-projects";
import {WriteRequests} from "./widgets/write-requests";
import {WriteCurrentMonth} from "./widgets/write-current-month";
import {WritePreviousMonth} from "./widgets/write-previous-month";
import {WriteCurrentYear} from "./widgets/write-current-year";
import {WritePreviousYear} from "./widgets/write-previous-year";
import {ReadProjects} from "./widgets/read-projects";
import {ErrorProjects} from "./widgets/error-projects";
import {UnusedProjects} from "./widgets/unused-projects";
import {ComputedSelections} from "./widgets/computed-selections";
import {CardCanvas} from "./templates/card-canvas";
import { CollectedObjects } from "./widgets/collected-objects";
import { MyChannels } from "./widgets/my-channels";
import { RequestedConsents } from "./widgets/requested-consents";
import { ChannelSubscribers } from "./widgets/channel-subscribers";
import { ChannelPendingSubscribers } from "./widgets/channel-pending-subscribers";
import { ChannelUnsubscribers } from "./widgets/channel-unsubscribers";
import { RemainingCredits } from "./widgets/remaining-credits";
import { Subscriptions } from "./widgets/subscriptions";
import { PendingRequestsList } from "./widgets/pending-requests-list";
import { MyObjects } from "./widgets/my-objects";
import { LastAnnounces } from "./widgets/last-announces";
import { LastOrgUpdated } from "./widgets/last-org-updated";
import { MyAnnounces } from "./widgets/my-announces";
import {BasicSearch} from "./widgets/basic-search";
import {AdvancedSearch} from "./widgets/advanced-search";
import {NewObject} from "./widgets/new-object";
import {MyData} from "./widgets/my-data";
import {CreateExtranetForm} from "./widgets/create-extranet-form";
import {PendingUsers} from "./widgets/pending-users";
import {CreateTranslationForm} from "./widgets/create-translation-form";
import { NewAnnounce } from "./widgets/new-announce";
import { EditAccount } from "./widgets/edit-account";

export const WidgetsComponents = {
    validationRequests: {component: ValidationRequests, label: "Demandes à valider", group: "Suivi des demandes"},
    deletionRequests: {component: DeletionRequests, label: "Demandes de suppression", group: "Suivi des demandes"},
    hidingRequests: {component: HidingRequests, label: "Demandes de masquage", group: "Suivi des demandes"},
    activeRequests: {component: ActiveRequests, label: "Demandes en cours", group: "Suivi des demandes"},
    sentRequests: {component: SentRequests, label: "Demandes envoyées", group: "Suivi des demandes"},
    receivedRequests: {component: ReceivedRequests, label: "Demandes reçues", group: "Suivi des demandes"},
    hiddenObjects: {component: HiddenObjects, label: "Objets masqués", group: "Qualité de la donnée"},
    brokenLinks: {component: BrokenLinks, label: "Liens cassés", group: "Qualité de la donnée"},
    quarantinedMedia: {component: QuarantinedMedia, label: "Multimédias en quarantaine", group: "Qualité de la donnée"},
    nearlyQuarantinedMedia: {component: NearlyQuarantinedMedia, label: "Multimédias proche de la quarantaine", group: "Qualité de la donnée"},
    datesToConfirm: {component: DatesToConfirm, label: "Dates à confirmer", group: "Qualité de la donnée"},
    unusedEntities: {component: UnusedEntities, label: "Entités non utilisées", group: "Qualité de la donnée"},
    objectsWithoutPicture: {component: ObjectsWithoutPicture, label: "Fiches sans images", group: "Qualité de la donnée"},
    pendingGeolocation: {component: PendingGeolocation, label: "Géolocalisation non validée", group: "Qualité de la donnée"},
    objectsWithoutDesc: {component: ObjectsWithoutDesc, label: "Offres sans descriptif court", group: "Qualité de la donnée"},
    expiredOpenings: {component: ExpiredOpenings, label: "Périodes d'ouverture périmées", group: "Qualité de la donnée"},
    obsoleteObjects: {component: ObsoleteObjects, label: "Fiches non modifiées depuis 1 an", group: "Qualité de la donnée"},
    archivedObjects: {component: ArchivedObjects, label: "Fiches masquées depuis + de 3 ans", group: "Qualité de la donnée"},
    unusedObjects: {component: UnusedObjects, label: "Fiches non diffusées", group: "Qualité de la donnée"},
    newObject: {component: NewObject, label: "Créer une fiche", group: "Saisie"},
    createExtranetForm: {component: CreateExtranetForm, label: "Créer un modèle de saisie extranet", group: "Saisie"},
    sentObjects: {component: SentObjects, label: "Saisies envoyées", group: "Saisie"},
    pendingObjects: {component: PendingObjects, label: "Saisies à valider", group: "Saisie"},
    requestedTranslations: {component: RequestedTranslations, label: "Traductions demandées", group: "Traduction"},
    pendingTranslations: {component: PendingTranslations, label: "Traductions à valider", group: "Traduction"},
    awaitingTranslations: {component: AwaitingTranslations, label: "Traductions à réaliser", group: "Traduction"},
    completedTranslations: {component: CompletedTranslations, label: "Derniers contrats clos", group: "Traduction"},
    createTranslationForm: {component: CreateTranslationForm, label: "Créer un modèle de saisie traducteur", group: "Traduction"},
    history: {component: History, label: "Dernières actions", group: "Historique"},
    lastOrgUpdated: {component: LastOrgUpdated, label: "Dernières structures mises à jour", group: "Historique"},
    writeProjects: {component: WriteProjects, label: "Projets en écriture", group: "Projets en écriture"},
    writeRequests: {component: WriteRequests, label: "Ecritures à valider", params: ['projetId'], presets: {projetId: '/v1/sit/widgets/projetsecriture/liste'}, group: "Projets en écriture"},
    writeCurrentMonth: {component: WriteCurrentMonth, label: "Fiches écrites par le projet ce mois", params: ['projetId'], presets: {projetId: '/v1/sit/widgets/projetsecriture/liste'}, group: "Projets en écriture"},
    writePreviousMonth: {component: WritePreviousMonth, label: "Fiches écrites par le projet le mois dernier", params: ['projetId'], presets: {projetId: '/v1/sit/widgets/projetsecriture/liste'}, group: "Projets en écriture"},
    writeCurrentYear: {component: WriteCurrentYear, label: "Fiches écrites par le projet cette année", params: ['projetId'], presets: {projetId: '/v1/sit/widgets/projetsecriture/liste'}, group: "Projets en écriture"},
    writePreviousYear: {component: WritePreviousYear, label: "Fiches écrites par le projet l'an passé", params: ['projetId'], presets: {projetId: '/v1/sit/widgets/projetsecriture/liste'}, group: "Projets en écriture"},
    readProjects: {component: ReadProjects, label: "Projets en lecture", group: "Projets en lecture"},
    errorProjects: {component: ErrorProjects, label: "Projets en erreur", params: ['projetId'], presets: {projetId: '/v1/sit/widgets/projetslecture/liste'}, group: "Projets en lecture"},
    unusedProjects: {component: UnusedProjects, label: "Projets non intégrés", params: ['projetId'], presets: {projetId: '/v1/sit/widgets/projetslecture/liste'}, group: "Projets en lecture"},
    computedSelections: {component: ComputedSelections, label: "Derniers calculs de sélections", params: ['projetId'], presets: {projetId: '/v1/sit/widgets/projetslecture/liste'}, group: "Projets en lecture"},
    cardCanvas: {component: CardCanvas, label: "Groupe de widgets", group: "Autres"},
    collectedObjects: {component: CollectedObjects, label: "Fiches collectées", group: "Consent"},
    myChannels: {component: MyChannels, label: "Mes channels", group: "Consent"},
    // requestedConsents: {component: RequestedConsents, label: "Demandes de consentements envoyées"},
    channelSubscibers: {component: ChannelSubscribers, label: "Abonnés aux channels", group: "Consent"},
    channelPendingSubscribers: {component: ChannelPendingSubscribers, label: "Demandes d'abonnement en attentes", group: "Consent"},
    channelUnsubscribers: {component: ChannelUnsubscribers, label: "Abonnements refusés", group: "Consent"},
    remainingCredits: {component: RemainingCredits, label: "Crédits consent restants", group: "Consent"},
    subscriptions: {component: Subscriptions, label: "Mes abonnements", group: "Consent"},
    pendingRequestsList: {component: PendingRequestsList, label: "Demandes en attente", group: "Consent"},
    myObjects: {component: MyObjects, label: "Mes fiches utilisées", group: "Consent"},
    myData: {component: MyData, label: "Mes données", group: "Mes données"},
    ownedObjects: {component: OwnedObjects, label: "Objets sous ma responsabilité", group: "Mes données"},
    delegatedObjects: {component: DelegatedObjects, label: "Objets délégués", group: "Mes données"},
    basicSearch: {component: BasicSearch, label: "Recherche intuitive", group: "Recherche"},
    advancedSearch: {component: AdvancedSearch, label: "Recherche avancée", group: "Recherche"},
    pendingUsers: {component: PendingUsers, label: "Utilisateurs en attente", group: "Mon compte"},
    editAccount: {component: EditAccount, label: "Modification de ma fiche", group: "Mon compte"},
    myAnnounces: {component: MyAnnounces, label: "Mes annonces", group: "Annonces"},
    lastAnnounces: {component: LastAnnounces, label: "Dernières annonces publiées", group: "Annonces"},
    newAnnounce: {component: NewAnnounce, label: "Nouvelle annonce", group: "Annonces"}
};

export const WidgetsColors = [{color: 'blue', value: '#168AC8'}, {color: 'darkblue', value: '#213162'},
    {color: 'red', value: '#F46754'}, {color: 'yellow', value: '#FCEF8B'}, {color: 'green', value: '#C7EAC4'}];

export const WidgetsFills = [{fill: 'none', label: 'Sans bordure'}, {fill: 'outline', label: 'Avec bordure'},
    {fill: 'solid', label: 'Aplat de couleur'}];

export const WidgetTemplate = (widget) => {
    if (['completedTranslations', 'history', 'writeProjects',
        'readProjects', 'errorProjects', 'unusedProjects', 
        'computedSelections', 'lastAnnounces', 'lastOrgUpdated',
        'myAnnounces', 'myObjects', 'pendingRequestsList'].indexOf(widget) !== -1) {
        return 'SimpleListAAC';
    }  else {
        return 'SingleCounter';
    }
};
