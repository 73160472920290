import {ErrorCircleRegular} from "@fluentui/react-icons";
import { SimpleListAAC } from "../templates/simple-list-aac";

export const ErrorProjects = (props) => {
    return (
        <SimpleListAAC widgetLabel={props.settings?.label}
                    fluentIcon={<ErrorCircleRegular/>}
                    fields={[
                        'projet',
                        'projet_url',
                        'tache',
                        'tache_url',
                        'selection',
                        'selection_url',
                    ]}
                    queryParams={{projetId: props.settings?.projetId}}
                    dataUrl="/v1/sit/widgets/projetslecture/enerreur"/>
    );
}
