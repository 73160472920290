import {useAuth0} from "@auth0/auth0-react";
import {Button} from "@fluentui/react-components";

export const SignupButton = () => {
    const {loginWithRedirect} = useAuth0();

    const handleSignUp = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/profile",
            },
            authorizationParams: {
                prompt: "login",
                screen_hint: "signup",
            },
        });
    };

    return (
        <Button className="button__sign-up" appearance={'primary'} onClick={handleSignUp}>Enregistrement</Button>
    );
};
