import { SimpleListAAC } from "../templates/simple-list-aac";

export const LastOrgUpdated = (props) => {
    return (
        <SimpleListAAC
        widgetLabel={props.settings?.label}
        icon="ContactInfo"
        fields={[
            'structure',
            'structure_url',
            'categorie',
        ]}
        dataUrl="/v1/aac/widgets/annuaire/liste/fichesmaj"/>
    );
}
