export function getLocalValue(key) {
    let store = {};
    if (global.localStorage) {
        try {
            store = JSON.parse(global.localStorage.getItem("apidae-dashboard")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return store[key];
}

export function saveLocalValue(key, value) {
    if (global.localStorage) {
        global.localStorage.setItem("apidae-dashboard", JSON.stringify({[key]: value}));
    }
}
