import {DocumentBulletListClockRegular} from "@fluentui/react-icons";
import {SingleCounter} from "../templates/single-counter";

export const WritePreviousMonth = (props) => {
    return (
        <SingleCounter widgetLabel={props.settings?.label} fluentIcon={<DocumentBulletListClockRegular/>}
                    queryParams={{projetId: props.settings?.projetId}}
                    dataUrl="/v1/sit/widgets/projetsecriture/nouvelles/moisdernier"/>
    );
}
