import {DocumentDismissRegular} from "@fluentui/react-icons";
import { SimpleListAAC } from "../templates/simple-list-aac";

export const UnusedProjects = (props) => {
    return (
        <SimpleListAAC widgetLabel={props.settings?.label} fluentIcon={<DocumentDismissRegular/>}
                    fields={[
                        'projet',
                        'projet_url',
                        'proprietaire',
                        'collaborateur',
                        ]}
                    queryParams={{projetId: props.settings?.projetId}}
                    dataUrl="/v1/sit/widgets/projetslecture/nonintegres"/>
    );
}
