import { SimpleListAAC } from "../templates/simple-list-aac";

export const MyAnnounces = (props) => {
    return (
        <SimpleListAAC
        widgetLabel={props.settings?.label} 
        icon="TextDocumentShared"
        fields={[
            'date',
            'annonce',
            'annonce_url',
            'categorie',
        ]}
        dataUrl="/v1/aac/widgets/annonce/liste/mesannonces"/>
    );
}
