import {useAuth0} from "@auth0/auth0-react";
import {Button} from "@fluentui/react-components";
import {Icon} from '@fluentui/react/lib/Icon';

const LoginIcon = () => <Icon iconName="Signin"/>;

export const LoginButton = () => {
    const {loginWithRedirect} = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/",
            },
            authorizationParams: {
                prompt: "login",
            },
        });
    };

    return (
        <Button appearance="transparent" onClick={handleLogin} icon={<LoginIcon/>}>
            Connexion
        </Button>
    );
};
