import {NavBarBrand} from "./nav-bar-brand";
import {NavBarButtons} from "./nav-bar-buttons";
import {NavBarTabs} from "./nav-bar-tabs";
import {makeStyles, shorthands} from "@fluentui/react-components";

const useStyles = makeStyles({
    container: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        flexShrink: 0,
        width: '100%',
        boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,.13),0 .3px .9px 0 rgba(0,0,0,.11)',
        zIndex: 300,
        backgroundColor: 'white'
    },
    content: {
        display: 'flex', alignItems: 'center', flexShrink: 0, height: '80px', maxWidth: '1200px',
        ...shorthands.flex(1), ...shorthands.margin('0'), ...shorthands.padding('0', '24px')
    }
});

export const NavBar = ({onModeChange}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <nav className={classes.content}>
                <NavBarBrand/>
                <NavBarTabs onModeChange={onModeChange}/>
                <NavBarButtons/>
            </nav>
        </div>
    );
};
