import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import {useAuth0} from "@auth0/auth0-react";
import {Icon} from "@fluentui/react/lib/Icon";
import {Button, makeStyles, shorthands, Spinner, tokens} from "@fluentui/react-components";
import {useContext} from "react";
import {ModeContext} from "../../page-layout";
import {httpHeaders} from "../../../services/queries";

const useStyles = makeStyles({
    btn: {width: '100%', height: '100%', ...shorthands.padding('5px', '18px')},
    title: {paddingLeft: '5px', lineHeight: 1, ...shorthands.flex(1, 1, 'auto')},
    counter: {
        marginLeft: '1rem', display: 'inline-block',
        ...shorthands.borderRadius(tokens.borderRadiusMedium), ...shorthands.padding('5px', '12px')
    }
});

export const SingleCounter = ({ icon, fluentIcon, appearance, widgetLabel, dataUrl, queryParams }) => {
    // console.debug('SingleCounter props: ' + JSON.stringify({appearance, dataUrl, queryParams}));
    const classes = useStyles();
    const mode = useContext(ModeContext), editable = mode === 'edit';
    const {getIdTokenClaims} = useAuth0();
    const CounterIcon = () => fluentIcon || <Icon iconName={icon}/>;
    const handleClick = (url) => {
        if (!editable) {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }
    }

    const urlParams = queryParams ? Object.keys(queryParams).map(p => `${p}=${queryParams[p]}`).join('&') : '';
    const queryUrl = `${process.env.REACT_APP_APIDAE_WS_URL}${dataUrl}${urlParams ? ('?' + urlParams) : ''}`;

    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: [queryUrl],
        queryFn: () => {
            return getIdTokenClaims().then(tokenData => {
                const idToken = tokenData['__raw'];
                // console.debug("token: " + JSON.stringify(tokenData));
                return axios
                    .get(queryUrl, httpHeaders(idToken))
                    .then((res) => res.data);
            });
        }
    });

    if (isLoading) return (
        <div className="pam txtcenter in_progress"><Spinner />Chargement en cours...</div>
    );

    if (error) return "Une erreur s'est produite : " + error.message;

    return (
        <Button className={classes.btn} appearance={appearance} icon={<CounterIcon/>} onClick={() => handleClick(data.link)}>
            <span className={classes.title}>{widgetLabel || data.title}</span><span className={classes.counter}>{data.value.count}</span>
        </Button>
    );
}
