import {DocumentSyncRegular} from "@fluentui/react-icons";
import { SimpleListAAC } from "../templates/simple-list-aac";

export const ComputedSelections = (props) => {
    return (
        <SimpleListAAC fluentIcon={<DocumentSyncRegular/>}
                    widgetLabel={props.settings?.label}
                    fields={[
                        'projet', 
                        'projet_url',
                        'date_dernier_export',
                        'etat',
                        'type',
                        'export_reference',
                        'tache',
                        'tache_url',
                        'nombreobjetstouristiquesexportes',
                        'nombreobjetstouristiquessupprimes',
                        'nombreobjetsliesexportes',
                        'nombreobjetsliessupprimes',
                    ]}
                    queryParams={{projetId: props.settings?.projetId}}
                    dataUrl="/v1/sit/widgets/projetslecture/dernierscalculs"/>
    );
}
