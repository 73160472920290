import {useAuth0} from "@auth0/auth0-react";
import {PageLayout} from "../components/page-layout";
import {makeStyles} from "@fluentui/react-components";

const useStyles = makeStyles({
    header: {paddingBottom: '2rem'},
    layout: {display: 'flex', flexDirection: 'row'},
    info: {
        flexGrow: 1, flexBasis: 'auto', flexShrink: 0, paddingLeft: '1rem'
    }
});

export const ProfilePage = () => {
    const {user} = useAuth0();
    const classes = useStyles();

    if (!user) {
        return null;
    }

    return (
        <PageLayout>
            <div>
                <h1 className={classes.header}>Mon profil</h1>
                <div>
                    <div className={classes.layout}>
                        <img
                            src={user.picture}
                            alt="Image de profil"
                        />
                        <div className={classes.info}>
                            <h3>{user.given_name} {user.family_name}</h3>
                            <span>{user.email}</span>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};
