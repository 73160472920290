import { v4 as uuidv4 } from 'uuid';

const formatDate = (d) => {
    return d.toISOString();
}

export const httpHeaders = (token) => { return {
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token
    }
}};

const GET_USER_DASHBOARD =
    `query getUserDashboard($userId: uuid!) {
        dashboard_dashboards(
            where: { user_id: { _eq: $userId } }, order_by: {updated_at: desc}, limit: 1
        ) {
            id 
            label 
            updated_at 
            components
        }
    }`;

export const getUserDashboardQuery = (userId) => {
    return {query: GET_USER_DASHBOARD, variables: {userId: userId}};
};

const UPSERT_USER_DASHBOARD =
    `mutation UpsertUserDashboard($userId: uuid!, $dashboardId: uuid!, $label: String!, $updatedAt: timestamptz, $selectedAt: timestamptz, $components: jsonb) {
        insert_dashboard_dashboards_one (
            object: {
                id: $dashboardId,
                user_id: $userId, 
                updated_at: $updatedAt,
                selected_at: $selectedAt,
                label: $label,
                components: $components
            },
            on_conflict: {
                constraint: dashboards_pkey,
                update_columns: [updated_at, selected_at, label, components]
            }
        ) {
            id
            user_id
            label
        }
    }
`

export const saveUserDashboardQuery = (userId, dashboardId, label, selectedAt, widgets) => {
    return {
        query: UPSERT_USER_DASHBOARD,
        variables: {
            userId: userId,
            dashboardId: dashboardId || uuidv4(),
            label: label,
            updatedAt: formatDate(new Date()),
            selectedAt: formatDate(selectedAt),
            components: {
                data: sortedWidgets(widgets)
            }
        }
    }
};

const sortedWidgets = (widgets) => {
    return widgets.sort((w1, w2) => {
        return (w1.y !== w2.y) ? (w1.y - w2.y) : (w1.x - w2.x);
    });
};
