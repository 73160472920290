import {PageLayout} from "../components/page-layout";
import {useAuth0} from "@auth0/auth0-react";
import {Button, Spinner} from "@fluentui/react-components";
import {useMutation, useQuery} from "@tanstack/react-query";
import axios from "axios";
import {getUserDashboardQuery, httpHeaders, saveUserDashboardQuery} from "../services/queries";
import {Dashboard} from "../components/dashboard/dashboard";

export const HomePage = () => {
    const {loginWithRedirect, isAuthenticated, getIdTokenClaims, user} = useAuth0();
    let userDashboard = {}, userId = user?.['https://hasura.io/jwt/claims']?.['x-hasura-user-id'],
        userRoles = JSON.parse(user?.['https://hasura.io/jwt/claims']?.['x-hasura-user-org-roles'] || '[]'),
        userOrgId = user?.['https://hasura.io/jwt/claims']?.['x-hasura-org-id'];

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/",
            },
            authorizationParams: {
                prompt: "login",
            },
        });
    };

    const {isLoading: dashboardLoading, error: dashboardError, data: dashboardData} = useQuery({
        queryKey: ["getUserDashboard"],
        queryFn: () => {
            return getIdTokenClaims().then(tokenData => {
                const idToken = tokenData['__raw'];
                return axios
                    .post(`${process.env.REACT_APP_APIDAE_DB_URL}/v1/graphql`, getUserDashboardQuery(userId), httpHeaders(idToken))
                    .then((res) => res.data);
            });
        },
        enabled: !!isAuthenticated
    });

    const onSave = useMutation({
        mutationFn: (dashboard) => {
            return getIdTokenClaims().then(tokenData => {
                const idToken = tokenData['__raw'], userId = user['https://hasura.io/jwt/claims']['x-hasura-user-id'];
                // console.debug('userId:' + userId + ' - dashboardId: ' + userDashboard.id);
                return axios
                    .post(`${process.env.REACT_APP_APIDAE_DB_URL}/v1/graphql`,
                        saveUserDashboardQuery(userId, userDashboard.id, userDashboard.label || "Mon tableau de bord",
                            new Date(), dashboard.widgets),
                        httpHeaders(idToken))
                    .then((res) => {
                        // console.debug("res.data: " + JSON.stringify(res.data));
                        const dashboardId = res.data?.insert_dashboard_dashboards_one?.id;
                        if (dashboardId) {
                            userDashboard.id = dashboardId;
                        }
                        return res.data;
                    });
            });
        },
    });

    if (isAuthenticated && dashboardLoading) return (
        <div className="pam txtcenter in_progress"><Spinner />Chargement du tableau de bord en cours...</div>
    );

    if (dashboardError) return (
        <div className="pam txtcenter">Une erreur s'est produite : {dashboardError.message}</div>
    );

    if (dashboardData) {
        let activeDashboard = dashboardData?.data?.dashboard_dashboards?.[0];
        if (activeDashboard) {
            let {id, label, userId, updatedAt, selectedAt, components} = activeDashboard;
            // Note : backward-compatibility with previous {widgets: [], layouts: []} structure
            const widgets = components?.data?.widgets || components?.data;
            userDashboard = {...userDashboard, ...{id, label, updatedAt, selectedAt, userId, widgets: widgets}};
        }
    }

    return (
        <PageLayout>
            <div className="nav-bar__buttons">
                {!isAuthenticated && (
                    <>
                        <div className="txtcenter">
                            <h1>Bienvenue</h1>
                            <p className="pal">Veuillez vous authentifier pour accéder à votre tableau de bord.</p>
                            <Button appearance={'primary'} onClick={handleLogin} size="large">
                                Je m'authentifie
                            </Button>
                        </div>
                    </>
                )}
                {isAuthenticated && (
                    <>
                        <Dashboard user={user} userRoles={userRoles} userDashboard={userDashboard} userOrgId={userOrgId} onSave={onSave}/>
                    </>
                )}
            </div>
        </PageLayout>
    );
}
