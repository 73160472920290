import { createLightTheme, createDarkTheme } from '@fluentui/react-components';

const customBrandRamp = {
    "10": "#213162",
    "20": "#243f73",
    "30":"#254d84",
    "40":"#255b95",
    "50":"#226ba6",
    "60":"#1e7ab7",
    "70":"#168ac8",
    "80": "#0097cb",
    "90": "#00a4cb",
    "100": "#21b0c9",
    "110": "#42bbc6",
    "120": "#5fc6c2",
    "130": "#7bcfc0",
    "140": "#95d9bf",
    "150": "#aee2c0",
    "160": "#c7eac4"
};
const apidaeFonts = 'poppins, Arial, sans-serif';

export const customLightTheme = {
    ...createLightTheme(customBrandRamp),
    fontFamilyBase: apidaeFonts
};
export const customDarkTheme = {
    ...createDarkTheme(customBrandRamp),
    fontFamilyBase: apidaeFonts
};
