import {Icon} from "@fluentui/react/lib/Icon";
import {Button, makeStyles, shorthands} from "@fluentui/react-components";
import {useContext} from "react";
import {ModeContext} from "../../page-layout";

const useStyles = makeStyles({
    btn: {width: '100%', height: '100%', ...shorthands.padding('5px', '18px')},
    title: {paddingLeft: '5px', lineHeight: 1, ...shorthands.flex(1, 1, 'auto')}
});

export const SingleButton = ({ icon, fluentIcon, appearance, widgetLabel, url, consent = false }) => {
    const classes = useStyles();
    const mode = useContext(ModeContext), editable = mode === 'edit';
    const ButtonIcon = () => fluentIcon || <Icon iconName={icon}/>;
    let fullUrl = "";
    if(consent) {
        fullUrl = `${process.env.REACT_APP_APIDAE_DB_URL}${url}`;
    }
    else {
        fullUrl = `${process.env.REACT_APP_APIDAE_BASE_URL}${url}`;
    }
    const handleClick = () => {
        if (!editable) {
            const newWindow = window.open(fullUrl, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }
    }

    return (
        <Button className={classes.btn} appearance={appearance} icon={<ButtonIcon/>} onClick={handleClick}>
            <div className={classes.title}>{widgetLabel}</div>
        </Button>
    );
}
