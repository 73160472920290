import GridLayout, {WidthProvider} from "react-grid-layout";
import {makeStyles, mergeClasses, Select} from "@fluentui/react-components";
import {useContext, useState} from "react";
import {ModeContext} from "../page-layout";
import {WidgetsComponents, WidgetTemplate} from "./reference";
import {getLocalValue, saveLocalValue} from "../../services/storage";
import {AddWidget} from "./add-widget";
import {EditWidget} from "./edit-widget";
import {ErrorWidget} from "./widgets/error-widget";
import DefaultWidgets from "../../assets/data/default-widgets.json";
import axios from "axios";
import {useAuth0} from "@auth0/auth0-react";
import {httpHeaders} from "../../services/queries";

const useStyles = makeStyles({
    layout: {},
    header: {display: 'flex', justifyContent: 'space-between', marginLeft: '10px', marginRight: '10px',
        '& > h1': {display: 'flex', alignItems: 'center'},
        '& > p': {textAlign: 'right'}},
    select: {marginLeft: '20px', '& > select': {lineHeight: '32px'}}
});

const widgetHeight = (w) => {
    if (w === 'cardCanvas') {
        return 3;
    } else {
        const t = WidgetTemplate(w);
        if (t === 'SimpleList' || t === 'SimpleListAAC') {
            return 3;
        }
        return 1;
    }
};

const getTimestamp = () => new Date().getTime();
const Grid = WidthProvider(GridLayout);

export const Dashboard = ({user, userRoles, userDashboard, userOrgId, onSave}) => {
    const classes = useStyles();
    const mode = useContext(ModeContext), editable = mode === 'edit';
    const {getIdTokenClaims} = useAuth0();

    const getStoredValue = (key) => {
        return getLocalValue('user-dashboard')?.[key];
    };

    const getDefaultWidgets = () => {
        let roleEntry = DefaultWidgets.find((entry) => userRoles.some((r) => r === entry.role));
        if (!roleEntry || roleEntry.widgets.length === 0) {
            roleEntry = DefaultWidgets.find((entry) => entry.role === 'DEFAULT');
        }
        return roleEntry.widgets.map((w) => {
            return {...buildWidget(w.widget), ...w};
        });
    };

    const buildWidget = (w) => {
        return {widget: w, w: 3, x: 0, y: 0, h: widgetHeight(w), i: `${w}-${getTimestamp()}`, settings: {}};
    };

    const applyPresets = (w) => {
        const widgetPresets = WidgetsComponents[w.widget].presets;
        const presetsQueries = [];
        let presetQuery;
        if (widgetPresets) {
            for (const p in widgetPresets) {
                presetQuery = getIdTokenClaims().then(tokenData => {
                    const idToken = tokenData['__raw'];
                    return axios.get(`${process.env.REACT_APP_APIDAE_WS_URL}${widgetPresets[p]}`, httpHeaders(idToken)).then((res) => {
                        const results = (res.data?.value?.values || []).map(v => v.id);
                        w.settings[p] = results.join(',');
                    });
                });
                presetsQueries.push(presetQuery)
            }
        }
        return Promise.all(presetsQueries);
    };

    const layoutToWidgets = (layoutArray) => {
        return layoutArray.map((w) => {
            const widgetIndex = widgetsLayout.findIndex((wi) => wi.i === w.i);
            const widget = {...widgetsLayout[widgetIndex], ...w};
            widget.widget ||= widget.i.split('-')[0];
            widget.settings ||= {};
            return widget;
        });
    };

    const layoutBottom = () => {
        const yMax = widgetsLayout.reduce((y, val) => { return val.y > y ? val.y : y;}, 0);
        return yMax + 1;
    }

    const saveWidgetsLayout = (newLayout, fromGrid = false) => {
        const newWidgets = fromGrid ? layoutToWidgets(newLayout) : newLayout;
        setWidgetsLayout(newWidgets);
        if (editable) {
            const updatedDashboard = {...userDashboard, widgets: newWidgets};
            saveLocalValue('user-dashboard', updatedDashboard);
            onSave.mutate(updatedDashboard);
        }
    }

    let widgets = userDashboard.widgets || getStoredValue('widgets');
    if (!widgets || widgets.length === 0) {
        widgets = getDefaultWidgets();
    }

    const [editModal, setEditModal] = useState(false);
    const [clickDisabled, setClickDisabled] = useState(false);
    const [nestedAdd, setNestedAdd] = useState(false);
    const [parentWidget, setParentWidget] = useState(null);
    const [currentWidget, setCurrentWidget] = useState(widgets[0]);
    const [widgetsLayout, setWidgetsLayout] = useState(widgets);
    const [compaction, setCompaction] = useState('none');

    const createWidget = (w) => {
        const newWidget = buildWidget(w);
        // console.debug('createWidget: ' + w);
        applyPresets(newWidget).then(() => {
            let updatedLayout = [...widgetsLayout];
            // console.debug('parentWidget: ' + parentWidget?.i + ' - createWidget: ' + JSON.stringify(newWidget));
            if (parentWidget) {
                const parentIndex = updatedLayout.findIndex((w) => w.i === parentWidget.i);
                updatedLayout[parentIndex].settings.widgets ||= [];
                updatedLayout[parentIndex].settings.widgets.push(newWidget);
            } else {
                newWidget.y = layoutBottom();
                updatedLayout.push(newWidget);
                refreshGridDisplay();
            }
            // console.debug('updatedLayout: ' + JSON.stringify(updatedLayout));
            saveWidgetsLayout(updatedLayout);
            setParentWidget(null);
        });
    };

    const editWidget = (w, parentW, isDisabled) => {
        if (isDisabled) {
            setClickDisabled(false);
        } else {
            setCurrentWidget(w);
            setParentWidget(parentW);
            setEditModal(true);
        }
    };

    const updateWidget = ({color, fill, label, projectId, deletion}) => {
        const updatedLayout = [...widgetsLayout];
        if (parentWidget) {
            let parentIndex = updatedLayout.findIndex((w) => w.i === parentWidget.i);
            let nestedWidgetIndex = updatedLayout[parentIndex].settings.widgets.findIndex((w) => w.i === currentWidget.i);
            if (parentIndex > -1 && nestedWidgetIndex > -1) {
                if (deletion) {
                    updatedLayout[parentIndex].settings.widgets.splice(nestedWidgetIndex, 1);
                } else {
                    updateWidgetSettings(updatedLayout[parentIndex].settings.widgets[nestedWidgetIndex], color, fill,
                        label, projectId);
                }
            } else {
                console.error(`Cannot find nested widget ${currentWidget.i} for parent ${parentWidget.i}`);
            }
        } else {
            let widgetIndex = updatedLayout.findIndex((w) => w.i === currentWidget.i);
            if (widgetIndex > -1) {
                if (deletion) {
                    updatedLayout.splice(widgetIndex, 1);
                } else {
                    updateWidgetSettings(updatedLayout[widgetIndex], color, fill, label, projectId);
                }
            } else {
                console.error(`Cannot find widget ${currentWidget.i}`);
            }
        }

        setEditModal(false);
        saveWidgetsLayout(updatedLayout);
        setParentWidget(null);
    };

    const updateWidgetSettings = (widget, color, fill, label, projectId) => {
        const projIds = (typeof projectId === 'string') ? (projectId || '').split(',').map((pId) => pId.replace(/\s+/g, '')).join(',') : projectId;
        widget.settings.color = color;
        widget.settings.fill = fill;
        widget.settings.projetId = projIds;
        widget.settings.label = label;
        return widget;
    }

    const addNestedWidget = (evt, parentId) => {
        const parentIndex = widgetsLayout.findIndex((w) => w.i === parentId);
        setParentWidget(widgetsLayout[parentIndex]);
        setNestedAdd(true);
    };

    const editNestedWidget = (evt, parentId, nestedWidget) => {
        const parentIndex = widgetsLayout.findIndex((w) => w.i === parentId);
        if (parentIndex > -1) {
            editWidget(nestedWidget, widgetsLayout[parentIndex], clickDisabled);
        }
    };

    const setAddModalState = (modalState) => {
        setNestedAdd(modalState);
        if (!modalState) {
            setParentWidget(null);
        }
    };

    const refreshGridDisplay = () => {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
            document.querySelector("footer.page-footer")
                .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }, 200);
    }

    const updateCompaction = (t) => {
        setCompaction(t.target.value);
    }

    let WidgetComponent;

    return (
        <>
            <header className={classes.header}>
                <h1>
                    Mon tableau de bord
                    {editable && (
                            <>
                                <AddWidget parentWidget={parentWidget} handleCreateWidget={(e) => createWidget(e)} isOpen={nestedAdd} openChange={(o) => setAddModalState(o)}></AddWidget>
                                <Select className={classes.select} value={compaction} onChange={updateCompaction}>
                                    <option key={'none'} value={'none'}>Pas de compactage</option>
                                    <option key={'vertical'} value={'vertical'}>Compactage vertical</option>
                                    <option key={'horizontal'} value={'horizontal'}>Compactage horizontal</option>
                                </Select>
                            </>
                    )}
                </h1>
                <p>{user.given_name}<br/>{user.name}</p>
            </header>
            {
                editable && (
                    <EditWidget key={`edit-${currentWidget.id}`} isOpen={editModal} widget={currentWidget} handleUpdateWidget={(widgetSettings) => updateWidget(widgetSettings)}
                                handleDeleteWidget={() => updateWidget({deletion: true})} openChange={(o) => setEditModal(o)}></EditWidget>
            )}
            <Grid
                className={mergeClasses('dashboard-grid', classes.layout)}
                cols={12}
                rowHeight={60}
                margin={[10, 10]}
                compactType={compaction === 'none' ? null : compaction}
                isDraggable={editable}
                isResizable={editable}
                onLayoutChange={(newLayout) => saveWidgetsLayout(newLayout, true)}
                onDragStop={() => setClickDisabled(true)}
            >
                {
                    widgetsLayout.map( (widget) => {
                        const {w, h, x, y, i} = widget;
                        if (WidgetsComponents[widget.widget]) {
                            WidgetComponent = WidgetsComponents[widget.widget].component;
                            return <div key={i} data-grid={{x, y, w, h}} className={`theme-${widget.settings?.color || 'blue'} fill-${widget.settings?.fill || 'outline'}`} onClick={() => editable ? editWidget(widget, null, clickDisabled) : true}>
                                <WidgetComponent {...widget} onNestedEdit={editNestedWidget} onNestedAdd={addNestedWidget} userOrgId={userOrgId}/>
                            </div>;
                        } else {
                            return <div key={i} data-grid={{x, y, w, h}} onClick={() => editable ? editWidget(widget, null, clickDisabled) : true}>
                                <ErrorWidget {...widget} />
                            </div>;
                        }
                    } )
                }
                {
                    widgetsLayout.length === 0 && (
                        <>
                            <p>Aucun widget sur votre tableau de bord. Cliquez sur "Ajouter un widget" pour personnaliser votre affichage.</p>
                        </>
                    )
                }
            </Grid>
        </>
    );
}
