import {NavBar} from "./navigation/desktop/nav-bar";
import {MobileNavBar} from "./navigation/mobile/mobile-nav-bar";
import {PageFooter} from "./page-footer";
import {makeStyles, shorthands} from "@fluentui/react-components";
import {createContext, useState} from "react";

const useStyles = makeStyles({
    layout: {display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%'},
    content: {
        flexGrow: 1, flexBasis: 'auto', flexShrink: 0, display: 'flex', flexDirection: 'column', marginTop: '2rem',
        maxWidth: '120rem', width: '100%', ...shorthands.padding('3rem'),
        '@media only screen and (max-width: 480px)': shorthands.padding('1.6rem')
    }
});

export const ModeContext = createContext(null);

export const PageLayout = ({children}) => {
    const classes = useStyles();
    const [mode, setMode] = useState('read');

    return (
        <ModeContext.Provider value={mode}>
            <div className={classes.layout}>
                <NavBar onModeChange={(m) => setMode(m)}/>
                <MobileNavBar/>
                <div className={classes.content}>{children}</div>
                <PageFooter/>
            </div>
        </ModeContext.Provider>
    );
};
