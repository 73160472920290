import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import {useAuth0} from "@auth0/auth0-react";
import {Icon} from "@fluentui/react/lib/Icon";
import {
    Card,
    CardHeader,
    makeStyles,
    shorthands,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableCellLayout, 
    Spinner, 
    TableHeader, 
    TableHeaderCell
} from "@fluentui/react-components";
import {httpHeaders} from "../../../services/queries";

const useStyles = makeStyles({
    card: {...shorthands.padding(0)},
    header: {fontWeight: 600, '& > div:last-child': {fontSize: '22px'}, ...shorthands.padding('8px', '18px')},
    content: {...shorthands.padding(0, '18px', '12px', '18px')},
    cellHead: {lineHeight: '2', fontWeight: 800, fontSize: '1.5em'},
    cell: {lineHeight: '1.1', '&:last-child': {width: '42px'}, '& a': {color: '#0078d4', textDecorationLine: 'underline'}}
});

// Schéma des données attendues pour chaque type de liste
const listType = {
    'liste-des-projets-en-lecture': ['id', 'projet', 'projet_url'],
    'projets-en-erreur': ['projet', 'projet_url', 'tache', 'tache_url', 'selection', 'selection_url'],
    'projets-non-integres': ['projet', 'projet_url', 'proprietaire', 'collaborateur',],
    'derniers-calculs-de-selections': ['projet', 'projet_url', 'date_dernier_export', 'etat', 'type', 'export_reference', 'tache', 'tache_url', 'nombreobjetstouristiquesexportes', 'nombreobjetstouristiquessupprimes', 'nombreobjetsliesexportes', 'nombreobjetsliessupprimes'],
    'contrats-clos': ['date', 'contrat', 'contrat_url', 'traducteur'],
    'historique-des-actions': ['fiche', 'fiche_url', 'date_modification', 'date_validation', 'proprietaire', 'proprietaire_url', 'modificateur', 'modificateur_url', 'validateur', 'validateur_url', 'mode_saisie', 'statut'],
    'liste-des-projets-en-ecriture': ['id', 'projet', 'projet_url'],
    'mes-fiches-exploitees': ['projet', 'projet_url', 'count'],
    'consentements-en-attentes': ['projet', 'projet_url', 'proprietaire', 'proprietaire_url', 'count'],
    'dernieres-annonces': ['date', 'annonce', 'annonce_url', 'structure', 'structure_url', 'categorie', 'detail'],
    'mes-annonces': ['date', 'annonce', 'annonce_url', 'categorie'],
    'dernieres-structures-maj': ['structure', 'structure_url', 'categorie'],
}

export const SimpleListAAC = ({ icon, fluentIcon, appearance, fields, widgetLabel, dataUrl, queryParams }) => {
    // console.debug('SimpleList props: ' + JSON.stringify({appearance, fields, dataUrl, queryParams, presetsUrl}));
    const classes = useStyles();
    const {getIdTokenClaims} = useAuth0();
    const CounterIcon = () => fluentIcon || <Icon iconName={icon}/>;

    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: [dataUrl],
        queryFn: () => {
            return getIdTokenClaims().then(tokenData => {
                const idToken = tokenData['__raw'];
                const urlParams = queryParams ? Object.keys(queryParams).map(p => `${p}=${queryParams[p]}`).join('&') : '';
                // console.debug("token: " + JSON.stringify(tokenData));
                return axios
                    .get(`${process.env.REACT_APP_APIDAE_WS_URL}${dataUrl}?${urlParams}`, httpHeaders(idToken))
                    .then((res) => res.data);
            });
        }
    });

    const headerTitle = (listData) => {
        const valuesCount = (listData?.value?.values || []).length;
        return `${valuesCount} ${data.title.toLowerCase()}`;
    };

    const formatDate = (d) => new Date(d).toLocaleDateString();

    if (isLoading) return (
        <div className="pam txtcenter in_progress"><Spinner />Chargement en cours...</div>
    );

    if (error) return (
        <div className="pam txtcenter">Une erreur s'est produite : {error.message}</div>
    );
    
    const dataPath = listType[data.slug];

    return (
        <div className="simple-list">
            <Card className={classes.card} appearance={appearance}>
                <CardHeader className={classes.header} header={widgetLabel || headerTitle(data)} action={<CounterIcon/>}/>
                <div className={classes.content}>
                    {data.value.values.length > 0 && (
                        <>
                            <Table size="extra-small" aria-label={data.description}>
                                {/* Nom des colonnes */}
                                <TableHeader>
                                    <TableRow>
                                        { data.value.headers.map((element, k) => (
                                            <TableHeaderCell className={classes.cellHead} key={`header-${k}`}>{element.libelle}</TableHeaderCell>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                {/* Contenu du tableau */}
                                <TableBody>
                                    {data.value.values.map((row, idx) => (
                                        <TableRow key={idx}>
                                            {dataPath.filter((element) => !element.match(/_url$/))
                                            .map((element, l) => {
                                                if(element.match(/^(date)|date_|_date/))
                                                    return (
                                                        <TableCell key={`${idx}-cell-${l}`} className={classes.cell}>{formatDate(row[element])}</TableCell>
                                                    )
                                                else {
                                                    if(element + '_url' in row)
                                                        return (
                                                            <TableCell key={`${idx}-cell-${l}`} className={classes.cell}><TableCellLayout truncate={true}>
                                                                <a href={row[element + '_url']} target="_blank" rel="noreferrer" >
                                                                    {row[element] ? row[element] : '-'}
                                                                </a>
                                                            </TableCellLayout></TableCell>
                                                        )
                                                    else {
                                                        if(typeof row[element] === 'string' && row[element].includes('\\n')){ 
                                                            var array = row[element].split('\\n')
                                                            if(array.length > 1) {
                                                                return (
                                                                    <TableCell key={`${idx}-cell-${l}`} className={classes.cell}><TableCellLayout truncate={true}>
                                                                    {
                                                                        array.map((part, j) => (
                                                                            <p key={`${idx}-${j}`}>{part}</p>
                                                                        ))
                                                                    }
                                                                    </TableCellLayout></TableCell>
                                                                )
                                                            }
                                                        }
                                                        return (
                                                            <TableCell key={`${idx}-cell-${l}`} className={classes.cell}><TableCellLayout truncate={true}>{row[element] ? row[element] : '-'}</TableCellLayout></TableCell>
                                                        )
                                                    }
                                                }
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                    {!data.value.values.length && (
                        <>
                            <p>Aucun résultat à l'heure actuelle.</p>
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
}
