import {CalendarEditRegular} from "@fluentui/react-icons";
import {SingleCounter} from "../templates/single-counter";

export const WriteCurrentYear = (props) => {
    return (
        <SingleCounter widgetLabel={props.settings?.label} fluentIcon={<CalendarEditRegular/>}
                    queryParams={{projetId: props.settings?.projetId}}
                    dataUrl="/v1/sit/widgets/projetsecriture/nouvelles/cetteannee"/>
    );
}
