import {SingleButton} from "../templates/single-button";

export const EditAccount = (props) => {
    const orgId = props.userOrgId;
    const url = `/annuaire/${orgId}/edition`;
    return (
        <SingleButton widgetLabel={props.settings?.label || 'Modification de ma fiche'} 
                    icon="EditContact"
                    url={url}
                    consent={true}
        />
    );
}
