import {NavLink} from "react-router-dom";
import logo from "../../../assets/logo_apidae_h.png";
import {makeStyles} from "@fluentui/react-components";

const useStyles = makeStyles({
    container: {display: 'flex', alignItems: 'center', height: '100%', marginRight: '64px'},
    logo: {height: '3.2rem', width: 'auto'}
});

export const NavBarBrand = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <NavLink to="/" className={classes.logo}>
                <img className={classes.logo} src={logo} alt="logo Apidae" width="122" height="36"/>
            </NavLink>
        </div>
    );
};
