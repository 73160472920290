import {useAuth0} from "@auth0/auth0-react";
import {Route, Routes} from "react-router-dom";
import {AuthenticationGuard} from "./components/authentication-guard";
import {CallbackPage} from "./pages/callback-page";
import {HomePage} from "./pages/home-page";
import {NotFoundPage} from "./pages/not-found-page";
import {ProfilePage} from "./pages/profile-page";
import {Spinner} from "@fluentui/react-components";
import logo from "./assets/logo_apidae_h.png";

export const App = () => {
    const {isLoading} = useAuth0();

    if (isLoading) {
        return (
            <div className="txtcenter pal">
                <div className="pal">
                    <img
                        src={logo}
                        alt="logo Apidae"
                        width="300"
                        height="auto"
                    />
                </div>
                <p>Chargement en cours...</p>
                <Spinner className="pal"/>
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route
                path="/compte"
                element={<AuthenticationGuard component={ProfilePage}/>}
            />
            <Route path="/auth-callback" element={<CallbackPage/>}/>
            <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
    );
};
