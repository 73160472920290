import {Button, Card, CardHeader, makeStyles, mergeClasses, shorthands} from "@fluentui/react-components";
import {useContext} from "react";
import {ModeContext} from "../../page-layout";
import {WidgetsComponents, WidgetTemplate} from "../reference";

const useStyles = makeStyles({
    layout: {width: '100%', height: '100%'},
    card: {width: '100%', height: '100%', ...shorthands.padding(0)},
    header: {fontWeight: 600, '& > div:last-child': {fontSize: '22px'}, ...shorthands.padding('12px', '18px')},
    content: {display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 2fr))', gridGap: '10px',
        '& .SimpleListAAC': {gridColumnStart: 1, gridColumnEnd: -1}, ...shorthands.padding(0, '18px', '12px', '18px')}
});

export const CardCanvas = ({i, settings, onNestedAdd, onNestedEdit}) => {
    const classes = useStyles();
    const mode = useContext(ModeContext), editable = mode === 'edit';

    const handleNestedCreate = (evt, parentId) => {
        evt.stopPropagation();
        onNestedAdd(evt, parentId);
    };

    const handleNestedEdit = (evt, parentId, widgetId) => {
        evt.stopPropagation();
        onNestedEdit(evt, parentId, widgetId);
    };

    let WidgetComponent;
    const AddWidget = <Button onClick={(e) => handleNestedCreate(e, i)} className={classes.btn} size="small">Ajouter un widget</Button>;

    return (
        <Card className={mergeClasses('card-canvas', classes.card)}>
            <CardHeader className={mergeClasses('card-header', classes.header)} header={settings?.label || 'Groupe de widgets'} action={editable && AddWidget}></CardHeader>
            <div className={classes.content}>
                {
                    (settings?.widgets || []).map( w => {
                        WidgetComponent = WidgetsComponents[w.widget].component;
                        return <div key={w.i} className={`${WidgetTemplate(w.widget)} theme-${w.settings?.color || 'blue'} fill-${w.settings?.fill || 'outline'}`} onClick={(e) => editable ? handleNestedEdit(e, i, w) : true}><WidgetComponent {...w} /></div>;
                    } )
                }
                {
                    (settings?.widgets || []).length === 0 && (
                        <>
                            <p>Aucun widget dans ce groupe.{(editable && <>Cliquez sur {AddWidget} pour personnaliser le contenu du groupe.</>)}</p>
                        </>
                    )
                }
            </div>
        </Card>
    );
}
