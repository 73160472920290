import { SimpleListAAC } from "../templates/simple-list-aac";

export const LastAnnounces = (props) => {
    return (
        <SimpleListAAC
        widgetLabel={props.settings?.label} 
        icon="TextDocument"
        fields={[
            'date',
            'annonce',
            'annonce_url',
            'structure',
            'structure_url',
            'categorie',
            'detail'
        ]}
        dataUrl="/v1/aac/widgets/annonce/liste/annonces"/>
    );
}
