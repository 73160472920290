import { SimpleListAAC } from "../templates/simple-list-aac";

export const PendingRequestsList = (props) => {
    return (
        <SimpleListAAC 
            widgetLabel={props.settings?.label} 
            icon="MailSchedule"
            fields={[
                'projet',
                'projet_url',
                'proprietaire',
                'proprietaire_url',
                'count',
            ]}
            dataUrl="/v1/aac/widgets/consent/liste/enattentes"/>
    );
}
