import {
    Button, Dialog,
    DialogActions,
    DialogBody, DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger, Input, Label,
    makeStyles, shorthands
} from "@fluentui/react-components";
import {WidgetsColors, WidgetsComponents, WidgetsFills} from "./reference";
import {useEffect, useState} from "react";

const useStyles = makeStyles({
    content: {
        marginTop: '10px', marginBottom: '20px',
        '& button.widget-color, & button.widget-fill': {height: '36px', marginRight: '4px', ...shorthands.border('4px', 'solid', 'white'), opacity: 0.9},
        '& button.widget-fill-outline:not(.active)': {outlineOffset: '-5px', ...shorthands.outline('1px', 'solid', '#242424')},
        '& button.active': {...shorthands.border('4px', 'solid', '#242424'), opacity: 1}
    },
    subtitle: {fontSize: '18px', '&:not(:first-child)': {marginTop: '20px'}},
    deleteBtn: {backgroundColor: '#F46754'},
    dialogActions: {'& button': {lineHeight: 1.1}},
    field: {
        marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center',
        '& label': {'marginRight': '10px'},
        '& span': {...shorthands.flex(1, 0, 'auto')}
    }
});

export const EditWidget = ({widget, isOpen, handleUpdateWidget, handleDeleteWidget, openChange}) => {
    const classes = useStyles();
    const [currentColor, setCurrentColor] = useState('blue');
    const [currentFill, setCurrentFill] = useState('outline');
    const [widgetLabel, setWidgetLabel] = useState('');
    const [projectId, setProjectId] = useState('');
    const widgetParams = WidgetsComponents[widget.widget]?.params || [];

    useEffect(() => {
        setCurrentColor(widget?.settings?.color || 'blue');
        setCurrentFill(widget?.settings?.fill || 'outline');
        setWidgetLabel(widget?.settings?.label || '');
        setProjectId(widget?.settings?.projetId || '');
    }, [widget]);

    // console.debug('Edit widget: ' + JSON.stringify(widget));

    return (
        <Dialog modalType="non-modal" open={isOpen} onOpenChange={(event, data) => openChange(data.open)}>
            <DialogSurface aria-describedby={undefined}>
                <DialogBody>
                    <DialogTitle>Modifier le widget</DialogTitle>
                    <DialogContent className={classes.content}>
                        {
                            widgetParams.length > 0 && (
                                <><h3 className={classes.subtitle}>Paramètres du widget</h3></>
                            )
                        }
                        {
                            widgetParams.indexOf('projetId') !== -1 && (
                                <div className={classes.field}>
                                    <Label required htmlFor="projectId">Identifiant du/des projet(s)</Label>
                                    <Input required id="projectId" value={projectId} onChange={(e) => setProjectId(e.target.value)}
                                           placeholder="Séparés par des virgules si plusieurs projets" />
                                    <br/>
                                </div>
                            )
                        }
                        {
                            <div className={classes.field}>
                                <Label htmlFor="widgetLabel">Libellé du widget</Label>
                                <Input id="widgetLabel" value={widgetLabel} onChange={(e) => setWidgetLabel(e.target.value)} />
                                <br/>
                            </div>
                        }

                        <h3 className={classes.subtitle}>Couleur du widget</h3>
                        {
                            WidgetsColors.map( c => {
                                return (
                                    <Button key={c.color} className={`widget-color widget-color-${c.color} ${currentColor === c.color ? 'active' : ''}`} onClick={() => setCurrentColor(c.color)}></Button>
                                );
                            })
                        }
                        <h3 className={classes.subtitle}>Style du widget</h3>
                        {
                            WidgetsFills.map( f => {
                                return (
                                    <Button key={f.fill} appearance={f.fill} className={`widget-fill widget-fill-${f.fill} ${currentFill === f.fill ? 'active' : ''}`} onClick={() => setCurrentFill(f.fill)}>{f.label}</Button>
                                );
                            })
                        }
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Fermer</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="primary" onClick={() => handleDeleteWidget()} className={classes.deleteBtn}>Supprimer le widget</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="primary" onClick={() => handleUpdateWidget({color: currentColor, fill: currentFill, label: widgetLabel, projectId: projectId})}>Valider</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
