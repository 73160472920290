import {SimpleListAAC} from "../templates/simple-list-aac";
import {HistoryRegular} from "@fluentui/react-icons";

export const History = (props) => {
    return (
        <SimpleListAAC widgetLabel={props.settings?.label}
                    fluentIcon={<HistoryRegular/>}
                    fields={[
                        'fiche',
                        'date_modification',
                        'date_validation', 
                        'proprietaire', 
                        'modificateur',
                        'validateur',
                        'mode_saisie',
                        'statut'
                    ]}
                    dataUrl="/v1/sit/widgets/journal/historique"/>
    );
}
